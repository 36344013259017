<template>
  <div>
    <div class="">
      <div class="echart-wrap">
        <div style="font-size: 1.1rem">数据统计</div>
        <div class="num-wrap">
          <div class="num-item">
            <div class="num-name">用户总数</div>
            <div class="num">{{ userCount.total || 0 }}</div>
          </div>
          <div class="num-item">
            <div class="num-name">教练总数</div>
            <div class="num">{{coachCount.total || 0}}</div>
          </div>
          <div class="num-item">
            <div class="num-name">总收益</div>
            <div class="num">{{ incomeAgent || 0 }}元</div>
          </div>
        </div>
      </div>

      <div class="echart-wrap">
        <div class="card-title al-c flex jc-b">
          <div></div>
          <timeChange @change="changeTimeUser"></timeChange>
        </div>

        <div class="num-wrap">
          <div class="num-item">
            <div class="num-name">在线用户数</div>
            <div class="num">{{ userCount.onlineCount || 0 }}</div>
          </div>
          <div class="num-item">
            <div class="num-name">新增用户数</div>
            <div class="num">{{ userCount.newCount || 0 }}</div>
          </div>
          <div class="num-item">
            <div class="num-name">会员数量</div>
            <div class="num">{{ userCount.memberCount || 0 }}</div>
          </div>
        </div>

        <div class="grid-2">
          <div class="echart-item">
            <div class="echart-title">用户新增</div>
            <div class="bar">
              <barEchart ename="userbar" :data="userBarData"></barEchart>
            </div>
          </div>
          <div class="echart-item">
            <div class="echart-title">用户来源</div>
            <div class="bar">
              <perEchart ename="userper" :data="userPerData"></perEchart>
            </div>
          </div>
        </div>
      </div>

      <div class="echart-wrap">
        <div class="card-title al-c flex jc-b">
          <div>收入金额：{{ incomeTotal }}</div>
          <timeChange @change="changeTimeIncome"></timeChange>
        </div>
        <div class="grid-2">
          <div class="echart-item">
            <div class="echart-title">收入数据</div>
            <div class="bar">
              <lineEchart ename="incomebar" :data="incomeBarData"></lineEchart>
            </div>
          </div>
          <div class="echart-item">
            <div class="echart-title">收入来源</div>
            <div class="bar">
              <rowBarEchart
                ename="incomeType"
                :data="incomeTypeData"
              ></rowBarEchart>
            </div>
          </div>
        </div>
      </div>

      <div class="echart-wrap">
        <div class="card-title al-c flex jc-b">
          <div></div>
          <timeChange @change="changeTimeCoach"></timeChange>
        </div>
        <div class="num-wrap">
          <div class="num-item">
            <div class="num-name">在线教练数</div>
            <div class="num">{{ coachCount.onlineCount || 0 }}</div>
          </div>
          <div class="num-item">
            <div class="num-name">新增教练数</div>
            <div class="num">{{ coachCount.newCount || 0 }}</div>
          </div>
          <div class="num-item">
            <div class="num-name">新增会员数</div>
            <div class="num">{{ coachCount.memberCount || 0 }}</div>
          </div>
        </div>
        <div class="grid-2">
          <div class="echart-item">
            <div class="echart-title">教练新增</div>
            <div class="bar">
              <barEchart ename="coachbar" :data="coachBarData"></barEchart>
            </div>
          </div>
          <div class="echart-item">
            <div class="echart-title">教练分布</div>
            <div class="bar">
              <perEchart ename="coachper" :data="coachPerData"></perEchart>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import timeChange from "./components/agent/timeChange.vue";
import lineEchart from "./components/echarts/lineEchart.vue";
import barEchart from "./components/echarts/barEchart.vue";
import perEchart from "./components/echarts/perEchart.vue";
import rowBarEchart from "./components/echarts/rowBarEchart.vue";
export default {
  components: {
    timeChange,
    lineEchart,
    barEchart,
    perEchart,
    rowBarEchart,
  },
  data() {
    return {
      incomeAgent:0,
      userBarData: { xData: [], yData: [] },
      userPerData: {},
      userCount: {},
      incomeTotal: 0,
      incomeBarData: { xData: [], yData: [] },
      incomeTypeData: { xData: [], yData: [] },

      coachBarData: { xData: [], yData: [] },
      coachPerData: {},
      coachCount: {},
    };
  },

  mounted() {
    this.statisticsByTime();
  },

  methods: {
    statisticsByTime() {
      let today = {
        list: [
          new Date().Format("yyyy-MM-dd") + " 00:00:00",
          new Date().Format("yyyy-MM-dd") + " 23:59:59",
        ],
        mode: "今日",
      };
      this.getAgentIncome()
      this.getUserBarData(today);
      this.getUserRegisterPlatformData(today);
      this.agentIncomeByTime(today);
      this.getIncomEchartsData(today);
      this.getCoachBarData(today);
      this.getCoachRoleData(today);
      this.user_count(today);
      this.coach_count(today);
    },
    //注册用户统计
    getAgentIncome() {
      let params = {
        cooperator_id: JSON.parse(localStorage.getItem("cooperator")).id,
      };
      this.$get("/user/cooperator/agentIncomeTotal", params).then((res) => {
        this.incomeAgent = res.data.data;
      });
    },
    //注册用户统计
    user_count(v) {
      let params = {
        start_time: v.list[0],
        end_time: v.list[1],
      };
      this.$get("/user/user/userNewAndOnline", params).then((res) => {
        this.userCount = res.data.data;
      });
    },
    //新增用户柱形图统计
    getUserBarData(v) {
      let params = {
        start_time: v.list[0],
        end_time: v.list[1],
      };
      if (v.mode == "今日") {
        params.mode = "hour";
      } else {
        params.mode = "day";
      }
      this.$get("/user/user/newCountByTime", params).then((res) => {
        let data = {};
        if (v.mode == "今日") {
          data = this.handlerDayData(res.data.data);
        } else if (v.mode == "本周") {
          data = this.handlerWeekData(res.data.data, v.list);
        } else if (v.mode == "本月") {
          data = this.handlerMonthData(res.data.data, v.list);
        }else{
            data = this.handlerRangeData(res.data.data, v.list);
        }
        this.userBarData = data;
      });
    },
    //用户注册来源
    getUserRegisterPlatformData(v) {
      let params = {
        start_time: v.list[0],
        end_time: v.list[1],
      };
      this.$get("/user/user/newUserPlatformByTime", params).then((res) => {
        let pic = [];
        for (let i = 0; i < res.data.data.name.length; i++) {
          pic.push({
            name: res.data.data.name[i],
            value: res.data.data.list[i],
          });
        }
        this.userPerData = { Data: pic };
      });
    },
    
    //收入柱形图统计
    agentIncomeByTime(v) {
      let params = {
        start_time: v.list[0],
        end_time: v.list[1],
        cooperator_id: JSON.parse(localStorage.getItem("cooperator")).id,
      };
      if (v.mode == "今日") {
        params.mode = "hour";
      } else {
        params.mode = "day";
      }
      this.$get("/user/adminBonusLog/incomeByTime", params).then((res) => {
        let data = {};
        if (v.mode == "今日") {
          data = this.handlerDayData(res.data.data);
        } else if (v.mode == "本周") {
          data = this.handlerWeekData(res.data.data, v.list);
        } else if (v.mode == "本月") {
          data = this.handlerMonthData(res.data.data, v.list);
        }else{
            data = this.handlerRangeData(res.data.data, v.list);
        }
        this.incomeBarData = data;
        this.incomeTotal = data.yData.reduce((per, cur) => {
          return per + cur;
        }, 0);
      });
    },
    //收入分布
    getIncomEchartsData(v) {
      let params = {
        start_time: v.list[0],
        end_time: v.list[1],
        pm: true,
        cooperator_id: JSON.parse(localStorage.getItem("cooperator")).id,
      };
      this.$get("user/adminBonusLog/cashSumByType", params).then((res) => {
        let s = {
          xData: [0, 0, 0],
          yData: ["其他","课包","教练会员"],
        };
        res.data.data.forEach((item) => {
          if (item.type === 5) {
            s.xData[0] = item.cash;
          }
          if (item.type === 1) {
            s.xData[1] = item.cash;
          }
          if (item.type === 7) {
            s.xData[2] = item.cash;
          }
        });

        this.incomeTypeData = s;
      });
    },

    //新增教练统计
    coach_count(v) {
      let params = {
        start_time: v.list[0],
        end_time: v.list[1],
      };
      this.$get("/user/coach/coachNewAndOnline", params).then((res) => {
        this.coachCount = res.data.data;
      });
    },
    //新增教练柱形图统计
    getCoachBarData(v) {
      let params = {
        start_time: v.list[0],
        end_time: v.list[1],
      };
      if (v.mode == "今日") {
        params.mode = "hour";
      } else {
        params.mode = "day";
      }
      this.$get("/user/coach/newCountByTime", params).then((res) => {
        let data = {};
        if (v.mode == "今日") {
          data = this.handlerDayData(res.data.data);
        } else if (v.mode == "本周") {
          data = this.handlerWeekData(res.data.data, v.list);
        } else if (v.mode == "本月") {
          data = this.handlerMonthData(res.data.data, v.list);
        }else{
            data = this.handlerRangeData(res.data.data, v.list);
        }
        this.coachBarData = data;
      });
    },
    //教练身份
    getCoachRoleData(v) {
      let params = {
        start_time: v.list[0],
        end_time: v.list[1],
      };
      this.$get("/user/coach/countByRole", params).then((res) => {
        let pic = [];
        for (let i = 0; i < res.data.data.name.length; i++) {
          pic.push({
            name: res.data.data.name[i],
            value: res.data.data.list[i],
          });
        }
        this.coachPerData = { Data: pic };
      });
    },

    changeTimeUser(v) {
      console.log(v);
      this.user_count(v);
      this.getUserBarData(v);
      this.getUserRegisterPlatformData(v);
    },
    changeTimeIncome(v) {
      this.agentIncomeByTime(v);
      this.getIncomEchartsData(v);
    },
    changeTimeCoach(v) {
      this.coach_count(v);
      this.getCoachBarData(v);
      this.getCoachRoleData(v);
    },
    //处理今日柱状图数据
    handlerDayData(list) {
      let name = [];
      let value = [];
      for (let i = 0; i < 24; i++) {
        name.push(this.zero(i) + ":00");
        value.push(0);
      }
      list.forEach((item) => {
        let i = parseInt(item.time.split(" ")[1].split(":")[0]) ;
        value[i] = item.count;
      });
      return {
        xData: name,
        yData: value,
      };
    },
    //处理本周柱状图数据
    handlerWeekData(list, date) {
      let name = [];
      let value = [];
      let s = new Date(date[0]).getTime();
      let day = 1000 * 24 * 60 * 60;
      for (let i = 0; i < 7; i++) {
        name.push(new Date(day * i + s).Format("MM-dd"));
        value.push(0);
      }
      list.forEach((item) => {
        let i = name.indexOf(item.time.slice(5, 10));
        if (i != -1) {
          value[i] = item.count;
        }
      });
      return {
        xData: name,
        yData: value,
      };
    },
    //处理本月柱状图数据
    handlerMonthData(list, date) {
      let name = [];
      let value = [];
      let s = new Date(date[0]).getTime();
      let n = new Date(date[1]).getDate();
      let day = 1000 * 24 * 60 * 60;
      for (let i = 0; i < n; i++) {
        name.push(new Date(day * i + s).Format("MM-dd"));
        value.push(0);
      }
      list.forEach((item) => {
        let i = name.indexOf(item.time.slice(5, 10));
        if (i != -1) {
          value[i] = item.count;
        }
      });
      return {
        xData: name,
        yData: value,
      };
    },
    //处理本月柱状图数据
    handlerRangeData(list, date) {
      let name = [];
      let value = [];
      let s = new Date(date[0]).getTime();
      let n = new Date(date[1]).getTime();
      let day = 1000 * 24 * 60 * 60;
      while (s < n) {
        name.push(new Date(s).Format("MM-dd"));
        value.push(0);
         s = s + day
      }
    
      list.forEach((item) => {
        let i = name.indexOf(item.time.slice(5, 10));
        if (i != -1) {
          value[i] = item.count;
        }
      });
      return {
        xData: name,
        yData: value,
      };
    },
    zero(v) {
      return v >= 10 ? v : "0" + v;
    },
  },
};
</script>

<style lang="less" scoped>
.count-item {
  padding: 20px 30px;
  font-size: 16px;
  background-color: #fff;
  box-sizing: border-box;
  border: 1px solid #ffe5b7;
  border-radius: 10px;
}
.card-title{
  margin-bottom: 10px;
}
.count-name {
  font-size: 16px;
}
.count-img {
  width: 30px;
  height: 30px;
}
.count-value {
  font-size: 22px;
}

.echart-wrap {
  width: 100%;
  padding: 2.5rem 3rem;
  font-size: 16px;
  background-color: #fff;
  box-sizing: border-box;
  // box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
  border-radius: 10px;
  margin-top: 1rem;
}

.echart-item {
  background-color: #fff;
  // box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  flex: 1;
  padding: 0 10px;
}
.echart-title {
  font-size: 1rem;
  font-weight: 400;
  color: #4c4d4f;
  padding: 20px 0 0 40px;
}

.bar {
  width: 100%;
  height: 17rem;
}

.grid-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
}

.num-wrap {
  display: flex;
  padding: 1rem;
}
.num-item {
  flex: 1;
  text-align: center;
}
.num-item ~ .num-item {
  border-left: 1px solid rgb(206, 205, 205);
}
.num-name {
  font-size: 1.1rem;
}
.num {
  font-size: 1.3rem;
  color: #fc7819;
  line-height: 2;
}
</style>