<template>
  <div :ref="ename" :style="{ width, height }"></div>
</template>
    
    <script>
export default {
  props: {
    data: {
      type: Object,
    },
    ename: {
      type: String,
    },
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "100%",
    },
    onclick: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      myChart: "",
      list: [],
      option: {
        tooltip: {
        trigger: 'axis'
    },
    xAxis: [{
        type: 'category',
        name:'',
        data: [],
       axisLine: {
            show: false,
            color:'#333'
        },
    
        axisLabel: {
            color: '#333',
            width:100
        },
        splitLine: {
            show: false
        },
        boundaryGap: false,
    }],
    yAxis: [{
        type: 'value',
        splitNumber: 4,
        name:'',
        splitLine: {
            lineStyle: {
                type: 'dashed',
                color: '#DDD'
            }
        },
        axisLine: {
            show: false,
            lineStyle: {
                color: '#333'
            },
        },
        nameTextStyle: {
            color: '#999'
        },
        splitArea: {
            show: false
        }
    }],
    series: [{
        name: '',
        type: 'line',
        data: [],
         itemStyle: {
            normal: {
                color: '#f7b851'
            }
        },
        lineStyle: {
            normal: {
                width: 3
            }
        }
    }]
      },
    };
  },
  computed: {
    full() {
      return this.$store.state.menuShow;
    },
  },
  watch: {
    full(newval) {
      this.myChart.resize();
    },
    data: {
      handler(newVal) {
        if (newVal && this.myChart != "") {
          this.list = newVal;
          this.updateOption();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getEchartData();
    window.addEventListener("resize", this.myChart.resize);
  },
  beforeDestroy() {
    // 移除窗口改变监听

    this.onclick && this.myChart.off("click", this.getClickInfo);
    window.removeEventListener("resize", this.myChart.resize);
  },
  methods: {
    getClickInfo(e) {
      console.log(e);

      this.$emit("click", e);
    },
    updateOption() {
      this.option.xAxis[0].data = this.list.xData;
      this.option.series[0].data = this.list.yData;
       this.option.xAxis[0].name = this.list.xName || '';
      this.option.yAxis[0].name = this.list.yName || '';
      this.myChart.setOption(this.option);
    },
    getEchartData() {
       this.option.xAxis[0].data = this.list.xData;
      this.option.series[0].data = this.list.yData;
       this.option.xAxis[0].name = this.list.xName || '';
      this.option.yAxis[0].name = this.list.yName || '';
      
      const chart = this.$refs[this.ename];
      if (chart) {
        this.myChart = this.$echarts.init(chart);
        this.myChart.setOption(this.option);
        this.onclick && this.myChart.on("click", this.getClickInfo);
      }
    },
  },
};
</script>
    
    <style lang="scss" scoped>
</style>