<template>
  <div class="flex time-wrap">
    <el-date-picker
      v-model="date"
      type="daterange"
      align="right"
      range-separator="至"
      start-placeholder="开始日期"
      end-placeholder="结束日期"
      value-format="yyyy-MM-dd"
      :picker-options="pickerOptions"
      @change="changeTime"
    >
    </el-date-picker>
    <p style="width: 50px"></p>
    <el-tabs v-model="activeName" @tab-click="tabChange">
      <el-tab-pane
        v-for="(item, index) in tabslist"
        :key="index"
        :label="item"
        :value="index"
      ></el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
export default {

  data() {
    return {
      tabslist: ["今日", "本周", "本月"],
      activeName: 0,
      tabName: "今日",
      pickerOptions: {
        shortcuts: [
          {
            text: "最近7天",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      date: "",
      start_time:'',
      end_time:''
    };
  },

  mounted() {},

  methods: {
    changeTime(v){
        if(!v){
            this.tabChange( this.tabName)
            return
        }
        let data = {mode:'range',list:[]}
        if(v[0]==v[1]){
            data.mode = '今日'
        }
        data.list[0] = v[0] + " 00:00:00"
        data.list[1] = v[1] + " 23:59:59"
      this.$emit('change',data)
    },
    tabChange(val) {
        this.date = ''
         let data = {mode:'range',list:[]}
      this.tabName = val.label;
      switch (val.label) {
        case "今日":
          this.start_time = new Date().Format("yyyy-MM-dd") + " 00:00:00";
		  this.end_time = new Date().Format("yyyy-MM-dd") + " 23:59:59";
          break;
        case "本周":
         this.getNowWeek();
          break;
        case "本月":
         this.getCurrentMonthFirst()
          break;
        default:
          break;
      }
      data.mode = val.label
        data.list[0] = this.start_time
        data.list[1] = this.end_time
      this.$emit('change',data)
    },
    //本月
			getCurrentMonthFirst() {
				var n = new Date();
			
				this.start_time = new Date(n.setDate(1)).Format("yyyy-MM-dd") + " 00:00:00";
				
				 const date = new Date();
				  const currentMonth = date.getMonth();
				  const nextMonth = currentMonth + 1;
				  const nextMonthFirstDay = new Date(date.getFullYear(), nextMonth, 1).getTime();
				  const oneDay = 24 * 60 * 60 * 1000;
				  this.end_time =  new Date(nextMonthFirstDay - oneDay).Format("yyyy-MM-dd") +" 23:59:59"
			},
			// 获取本周
			getNowWeek() {
				let time = new Date(); // 可入参指定时间
				let year = time.getFullYear();
				let month = time.getMonth();
				let date = time.getDate(); // 获取日期
				let day = time.getDay(); // 获取周几，0-6，0表示周日
				let _day = day == 0 ? 7 : day;
				// 获取周一日期
				this.start_time = new Date(year, month, date - _day + 1).Format("yyyy-MM-dd") + " 00:00:00";
				// 获取周日日期
				this.end_time = new Date(new Date(this.start_time).getTime() + 6 * 24 * 3600 * 1000).Format("yyyy-MM-dd") +" 23:59:59"
			},
  },
};
</script>

<style lang="less" scoped>
.time-wrap{
    background-color: #fff;
}
</style>